/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle  */
/* eslint-disable react/jsx-props-no-spreading */

import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { useRouter } from 'next/router';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box, Chip, Tooltip, Typography, useMediaQuery,
} from '@mui/material';
import { debounce, throttle } from 'lodash';
import { filterOptions } from '../../pages/index';
import Cookies from 'js-cookie';

const PREFIX = 'AutocompleteSearchItem';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    '& .MuiAutocomplete-paper:first-of-type': {
      '& .options': {
        backgroundColor: 'yellow',
      },
    },
  },
}));

const reCaptchaV3Key = process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY;

export default function AutocompleteSearchItem() {
  const phone = useMediaQuery('(min-width:600px)');
  const router = useRouter();
  const context = useContext(filterOptions);

  const [state, setstate] = useState([]);
  const { t } = useTranslation();
  const [input, setInput] = useState(context.data.inputField ?? '');

  const throttleFetchOptions = useMemo(
    () => throttle((value) => {
      if (process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE === 'true') {
        const { grecaptcha } = window;
        grecaptcha.ready(async () => {
          const recaptchaAction = { action: 'autoComplete' };
          grecaptcha.execute(reCaptchaV3Key, recaptchaAction)
            .then((token) => {
              axios
                .post(`api/autocomplete/${value}`, {
                  token,
                  language: Cookies.get('next-i18next'),
                })
                .then((response) => {
                  if (value !== undefined && response.status !== 500) {
                    response.data.payload.autocomplete.unshift({
                      title: `${value}`,
                      type: 'Επιχείρηση',
                      id: -1,
                    });

                    setstate(response.data.payload.autocomplete);
                  }
                });
            });
        });
      } else {
        const token = null;
        axios
          .post(`api/autocomplete/${value}`, {
            token,
            language: Cookies.get('next-i18next'),
          })
          .then((response) => {
            if (value !== undefined && response.status !== 500) {
              response.data.payload.autocomplete.unshift({
                title: `${value}`,
                type: 'Επιχείρηση',
                id: -1,
              });

              setstate(response.data.payload.autocomplete);
            }
          });
      }
    }, 500),
    [],
  );

  const debounceFetchOptions = useMemo(
    () => debounce(
      (value) => {
        if (process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE === 'true') {
          const { grecaptcha } = window;
          grecaptcha.ready(async () => {
            const recaptchaAction = { action: 'autoComplete' };
            grecaptcha
              .execute(reCaptchaV3Key, recaptchaAction)
              .then((token) => {
                axios
                  .post(`api/autocomplete/${value}`, {
                    token,
                    language: Cookies.get('next-i18next'),
                  })
                  .then((response) => {
                    if (value !== undefined && response.status !== 500) {
                      response.data.payload.autocomplete.unshift({
                        title: `${value}`,
                        type: 'Επιχείρηση',
                        id: -1,
                      });
                      setstate(response.data.payload.autocomplete);
                    }
                  });
              });
          });
        } else {
          const token = null;
          axios
            .post(`api/autocomplete/${value}`, {
              token,
              language: Cookies.get('next-i18next'),
            })
            .then((response) => {
              if (value !== undefined && response.status !== 500) {
                response.data.payload.autocomplete.unshift({
                  title: `${value}`,
                  type: 'Επιχείρηση',
                  id: -1,
                });
                setstate(response.data.payload.autocomplete);
              }
            });
        }
      },
      500,
      {
        maxWait: 250,
        leading: false,
        trailing: true,
      },
    ),
    [],
  );

  useEffect(async () => {
    if (
      input?.length !== 0
      && input?.length >= 2
      && input !== ''
      && input !== null
      && input.trim() !== ''
      && input.trim() !== '000000000'
    ) {
      if (input.length <= 5) {
        throttleFetchOptions(input.trim());
      } else {
        debounceFetchOptions(input.trim());
      }
    }
  }, [input]);

  useEffect(() => {
    if (input === null || input === '') {
      setInput(context.data.inputField);
    }
  }, [input]);
  return (
    <Root className={classes.root}>
      <Autocomplete
        id="AutocompleteSearchItem"
        options={state}
        inputValue={input !== null ? input : ''}
        classes={{ root: classes.root }}
        getOptionLabel={(option) => option.title ?? ''}
        onInputChange={(event, value, reason) => {
          if (reason !== 'reset') {
            const searchValue = value;
            setInput(value !== null ? searchValue : '');
            context.setData({
              ...context.data,
              inputField: searchValue,
            });
          }
        }}
        onChange={(event, value, reason) => {
          if (reason === 'selectOption') {
            if (value.id === -1) {
              setInput(value.title);
              context.setData({
                ...context.data,
                inputField: value.title.trim(),
              });
              context.data.inputField = value.title.trim();
              context.searchFunction(context.data);
            } else {
              router.push(
                value.arGemi
                  ? `/company/${value.arGemi}`
                  : `/person/${value.afm}`,
              );
            }
          }
        }}
        freeSolo
        renderOption={(props, option) => (
          <li {...props} key={option.arGemi}>
            <Box
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              display="flex"
              flexDirection="row"
              fontWeight="bold"
              width="100%"
              my="1rem"
              minWidth="100%"
            >
              <Box flexGrow="3" display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                  {option.id === -1 && (
                    <span style={{ pointerEvents: 'none' }}>
                      {t('Αναζήτηση για:')}
                      &ensp;
                    </span>
                  )}
                  <span>{option.co_name}</span>
                  {typeof option?.title !== 'undefined' ? (
                    <Typography
                      variant="body"
                      style={{
                        color:
                          option.id !== -1 || option.type !== 'Πρόσωπο'
                            ? 'gray'
                            : 'black',
                      }}
                    >
                      {option.title}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>

                {option.id !== -1 && (
                  <Box display="flex" flexWrap={phone ? 'nowrap' : 'wrap'}>
                    <Box marginRight="1em">
                      <Chip
                        size="small"
                        style={{
                          backgroundColor: '#194b8c',
                          color: 'white',
                          marginRight: '.5em',
                        }}
                        label={`${t('ΑΦΜ')}`}
                      />
                      <span>{option.afm}</span>
                    </Box>
                    {option.type === 'Επιχείρηση' && (
                      <Box marginRight="1em">
                        <Chip
                          size="small"
                          style={{ backgroundColor: '#194b8c', color: 'white' }}
                          label={`${t('Αριθμός ΓΕΜΗ')}`}
                        />
                        {' '}
                        <span>{option.arGemi}</span>
                      </Box>
                    )}
                    {option.type === 'Επιχείρηση' && (
                      <Box marginRight="1em">
                        <Chip
                          size="small"
                          style={{ backgroundColor: option?.companyStatusId === 3 ? 'green' : 'red', color: 'white' }}
                          label={option?.companyStatus}
                        />
                      </Box>
                    )}
                    {option.type === 'Επιχείρηση' && (
                      <Box>
                        <Typography color="grey.800">{t(option?.branchType)}</Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              {phone && (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                alignSelf="flex-end"
                flexGrow="0"
              >
                {option.id !== -1 && (
                  <span>
                    {option.type === 'Επιχείρηση' ? (
                      <Tooltip title={t('Επιχείρηση')} arrow>
                        <BusinessIcon color="primary" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={t('Φυσικό πρόσωπο που σχετίζεται με Επιχείρηση')}
                        arrow
                      >
                        <PersonIcon color="primary" />
                      </Tooltip>
                    )}
                  </span>
                )}
                <Tooltip title={t('Μετάβαση')} arrow>
                  {option.id === -1 ? (
                    <SearchIcon style={{ marginLeft: '40%' }} color="primary" />
                  ) : (
                    <ExitToAppRoundedIcon color="primary" />
                  )}
                </Tooltip>
              </Box>
              )}
            </Box>
          </li>
        )}
        filterOptions={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                context.searchFunction(context.data);
              }
            }}
            label={t(
              'Αναζήτηση με στοιχεία επιχειρήσεων',
            )}
            variant="outlined"
            title={t('Αναζήτηση επιχείρησης με Επωνυμία, Διακριτικό τίτλο ή μέρος αυτών, ΑΦΜ, αριθμό ΓΕΜΗ.')}
          />
        )}
      />
    </Root>
  );
}
