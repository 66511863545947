/*  eslint-disable  */
import React from 'react';
import FormControl from '@mui/material/FormControl';
import AutocompleteSearchItem from './AutocompleteSearchItem';
import { useMediaQuery } from '@mui/material';

export default function SearchBar() {
  const phone = useMediaQuery('(min-width:600px)');
  return (
    <FormControl style={!phone ? {width : "90%"} : {}}
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      fullWidth
    >
      <AutocompleteSearchItem />
    </FormControl>
  );
}
